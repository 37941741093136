import React, { Fragment } from 'react'
// @mui
import Grid from "@mui/material/Grid"
// react icons
import EmployeeCards from '../components/EmployeeCards';
// components
import NewsCardEmployee from '../components/NewsCardEmployee';

const DashboardEmployee = () => {
    return (
        <div className='bg-[#F2F2F2] px-8 py-12'>
            <Grid container xs={12}>
                <Grid container xs={12} spacing={2} className='justify-between' align="left">
                    <Grid item>
                        <h1 className='text-[#050009] font-medium text-3xl'>
                            Welcome to the Sales Portal <br/>
                            Mr. Max Mustermann
                        </h1>
                        <p className='text-[#384041] text-md mt-6'>These projects are available to you:</p>
                    </Grid>
                    <Grid item className='px-4 self-end'>
                        <h2 className='font-medium text-xl text-[#6D7879]'>Current Sales News</h2>
                    </Grid>
                </Grid>
                <Grid container xs={12} className="mt-8">
                    <Grid container lg={8} xs={12} spacing={2}>
                        <Grid item lg={6} xs={12}>
                            <EmployeeCards />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <EmployeeCards />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <EmployeeCards />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <EmployeeCards />
                        </Grid>
                    </Grid>
                    <Grid container lg={4} xs={12} className="max-lg:mt-4 lg:pl-4" align="center">
                        <div className='sticky top-4 bg-white p-4 rounded-xl shadow-lg max-h-[95vh] overflow-auto'>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <button className='bg-[#FFC734] text-white font-medium w-[200px] rounded-md py-1'>More News</button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default DashboardEmployee