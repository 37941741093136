import React from 'react'
// @mui
import Grid from '@mui/material/Grid'

const CustomerLogin = () => {
    return (
        <Grid container xs={12}>
            <Grid container lg={6} md={6} xs={12} className='bg-[#F2F2F2] max-md:h-[100vh]'>
                <Grid item xs={12} className='px-10 md:px-20 self-center'>
                    <div className=''>
                        <img 
                            width="100px"
                            src={require('../../assets/Safe authorization and password protection.png')} 
                            alt="" 
                        />
                        <h2 className='text-3xl mt-4 font-medium'>COMPANY NAME</h2>
                        <h2 className='text-3xl mt-2 font-medium'>Project Presentation</h2>
                        <p className='mt-6 font-medium text-lg'>Please enter your password</p>
                        <input 
                            type="text" 
                            placeholder="Password" 
                            className='mt-4 w-full border border-[#E4E4E4] py-3 px-6 bg-white rounded-lg shadow-md' 
                        />
                        <Grid item xs={12} align="center" className='pt-10'>
                            <button className='text-white bg-[#FFC734] rounded-lg px-12 py-2 font-semibold'>LOGIN</button>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            <Grid container lg={6} md={6} xs={12} className=''>
                <img 
                    src={require('../../assets/image 1.png')} 
                    alt=""
                    className='max-md:hidden md:max-h-[100vh] w-full'
                />
            </Grid>
        </Grid>
    )
}

export default CustomerLogin