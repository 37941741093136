import React from 'react'
import { Route, Routes, Navigate } from "react-router-dom";
// CSS
import "./App.css";
// Layouts
import SuperAdmin from './layout/SuperAdmin';
import Admin from './layout/Admin';
import Employee from './layout/Employee'
// Pages
import Login from "./pages/Login"
// -> Super Admin
import Dashboard from './pages/superadmin/Dashboard';
import ManageUser from './pages/superadmin/ManageUser';
import ProjectsSuper from './pages/superadmin/ProjectsSuper';
import ProjectSuper from './pages/superadmin/ProjectSuper';
import NewsSuper from './pages/superadmin/NewsSuper';
// -> Admin
import DashboardAdmin from './pages/admin/DashboardAdmin';
import ManageUserAdmin from './pages/admin/ManageUserAdmin';
import ProjectsAdmin from './pages/admin/ProjectsAdmin';
import ProjectAdmin from './pages/admin/ProjectAdmin';
import NewsAdmin from './pages/admin/NewsAdmin';
// -> Employee
import DashboardEmployee from './pages/employee/DashboardEmployee';
import NewsEmployee from './pages/employee/NewsEmployee';
import ProjectsEmployee from './pages/employee/ProjectsEmployee';
import ProjectEmployee from './pages/employee/ProjectEmployee';
import ProfileEmployee from './pages/employee/ProfileEmployee';
import ProfilePersonalEmployee from './pages/employee/ProfilePersonalEmployee';
import ProfileFileUploadEmployee from './pages/employee/ProfileFileUploadEmployee';
// -> Customer
import CustomerLogin from './pages/customer/CustomerLogin'
import CustomerDashboard from './pages/customer/CustomerDashboard'
const App = () => {
  return (
    <>
      <div className='app'>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          {/* Super Admin */}
          <Route path="/super-admin/dashboard" element={<SuperAdmin><Dashboard /></SuperAdmin>} />
          <Route path="/super-admin/manage-users" element={<SuperAdmin><ManageUser /></SuperAdmin>} />
          <Route path="/super-admin/projects" element={<SuperAdmin><ProjectsSuper /> </SuperAdmin>} />
          <Route path="/super-admin/project/:id" element={<SuperAdmin><ProjectSuper /> </SuperAdmin>} />
          <Route path="/super-admin/news/add" element={<SuperAdmin><NewsSuper /> </SuperAdmin>} />
          {/* Admin */}
          <Route path="/admin/dashboard" element={<Admin><DashboardAdmin /></Admin>} />
          <Route path="/admin/manage-users" element={<Admin><ManageUserAdmin /></Admin>} />
          <Route path="/admin/projects" element={<Admin><ProjectsAdmin /> </Admin>} />
          <Route path="/admin/project/:id" element={<Admin><ProjectAdmin /> </Admin>} />
          <Route path="/admin/news/add" element={<Admin><NewsAdmin /> </Admin>} />
          {/* Employee */}
          <Route path="/employee/dashboard" element={<Employee><DashboardEmployee /></Employee>} />
          <Route path="/employee/news" element={<Employee><NewsEmployee /></Employee>} />
          <Route path="/employee/projects" element={<Employee><ProjectsEmployee /> </Employee>} />
          <Route path="/employee/project/:id" element={<Employee><ProjectEmployee /> </Employee>} />
          <Route path="/employee/profile" element={<Employee><ProfileEmployee /></Employee>} />
          <Route path="/employee/profile/personal-information" element={<Employee><ProfilePersonalEmployee /></Employee>} />
          <Route path="/employee/profile/file-upload" element={<Employee><ProfileFileUploadEmployee /></Employee>} />
          {/* Customer */}
          <Route path="/customer/login" element={<CustomerLogin />} />
          <Route path="/customer/dashboard" element={<CustomerDashboard />} />
        </Routes>
      </div>
    </>
  )
}

export default App