import React from 'react'
// @mui
import Grid from '@mui/material/Grid'
import EmployeeCards from '../components/EmployeeCards'
// react icons
import { FaCamera, FaPhoneAlt } from "react-icons/fa";
import { MdOutlineSmartphone } from "react-icons/md";
import { IoIosMail } from "react-icons/io";

const CustomerDashboard = () => {
    return (
        <>
            <header className=''>
                <img 
                    src={require('../../assets/image 2.png')} 
                    alt="" 
                    className='w-full h-[90px]' 
                />
            </header>
            <Grid container xs={12} className='bg-[#D9D9D9] py-12'>
                <Grid container xs={12} spacing={4}>
                    <Grid item lg={6} md={6} xs={12} align="left">
                        <h1 className='mx-8 text-3xl'>
                            Welcome to the Sales Portal <br/>
                            Mr. Max Mustermann
                        </h1>
                        <div className='bg-white p-4 max-w-[500px] mx-8 mt-12 rounded-xl shadow-xl'>
                            <Grid item xs={12} align="left">
                                <p className='text-lg'>Ihr Persönlicher Ansprechpartner:</p>
                                <h2 className='text-black text-xl font-medium'>Mr. Max Mustermann</h2>
                                <p className='text-black text-md font-light my-1'>Job Title</p>
                                <div className='my-2'>
                                    <img 
                                        src={require('../../assets/young-handsome-man-travelling-with-bag 1.png')} 
                                        alt="" 
                                        className='w-full' 
                                    />
                                    <div className='flex justify-end h-0 relative -left-2 -top-12'>
                                        <div className='rounded-full bg-[#FFC734] w-9 h-9 p-2 flex justify-end cursor-pointer'>
                                            <FaCamera className="text-white text-xl" />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <div className='flex items-center'>
                                        <div className='self-center'>
                                            <MdOutlineSmartphone className='text-[#FFC734] text-lg' />
                                        </div>
                                        <div className='mx-2 self-center'>
                                            <p>0151 123456789</p>
                                        </div>
                                    </div>
                                    <div className='flex items-center mt-2'>
                                        <div className='self-center'>
                                            <IoIosMail className='text-[#FFC734] text-lg' />
                                        </div>
                                        <div className='mx-2 self-center'>
                                            <p>max.mustermann@domain.de</p>
                                            </div>
                                        </div>
                                        <div className='flex items-center mt-2'>
                                            <div className='self-center'>
                                                <FaPhoneAlt className='text-[#FFC734] text-lg' />
                                            </div>
                                            <div className='mx-2 self-center'>
                                                <p>0151 123456789</p>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <EmployeeCards />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CustomerDashboard