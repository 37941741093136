import React from 'react'
// @mui
import Grid from "@mui/material/Grid"

const EmployeeCards = () => {
    return (
        <>
            <div className='bg-white rounded-xl shadow-lg py-4 px-6'>
                <h2 className='text-lg font-bold'>Building Exterior</h2>
                <img 
                    src={require('../../assets/Rectangle 6.png')} 
                    alt="building" 
                    width="100%"
                    className='mt-4' 
                />
                <table className='w-full mt-4'>
                    <tbody className='grid'>
                        <tr className='my-[5px]'>
                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[180px] sm:w-[180px] pr-2'>Project type:</td>
                            <td className='max-sm:min-w-[120px] w-[120px]'>roof area / open space</td>
                        </tr>
                        <tr className='my-[5px]'>
                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[180px] sm:w-[180px] pr-2'>Sales type:</td>
                            <td className=''>Global / divisible / purchase model</td>
                        </tr>
                        <tr className='my-[5px]'>
                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[180px] sm:w-[180px] pr-2'>Project size in kWp:</td>
                            <td className=''>1000</td>
                        </tr>
                        <tr className='my-[5px]'>
                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[180px] sm:w-[180px] pr-2'>Speciﬁc annual yield in kWh/kWp: </td>
                            <td className=''>2000</td>
                        </tr>
                        <tr className='my-[5px]'>
                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[180px] sm:w-[180px] pr-2'>Purchase price in € per kWp: </td>
                            <td>100 EURO</td>
                        </tr>
                        <tr className='my-[5px]'>
                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[180px] sm:w-[180px] pr-2'>Total price of the system in €:</td>
                            <td>100.000 EURO</td>
                        </tr>
                        <tr className='my-[5px]'>
                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[180px] sm:w-[180px] pr-2'>Return after costs of EEG and direct marketing:</td>
                            <td className=''>500 EURO</td>
                        </tr>
                        <tr className='my-[5px]'>
                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[180px] sm:w-[180px] pr-2'>individual ﬁeld:</td>
                            <td className=''>Demo Text</td>
                        </tr>
                    </tbody>
                </table>
                <Grid item xs={12} align="center" className='pt-6'>
                    <button className='bg-[#FFC734] text-white font-medium w-[200px] rounded-md py-1'>Project Details</button>
                </Grid>
            </div>
        </>
    )
}

export default EmployeeCards