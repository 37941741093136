import React, { useState } from 'react'
import Grid from "@mui/material/Grid"
// react icons
import { FaChevronLeft } from "react-icons/fa6";



const ProfilePersonalEmployee = () => {
    
    return (
        <>
            <div className='bg-[#F2F2F2] px-8 py-12 md:min-h-[100vh]'>
                <Grid container justifyContent='center' spacing={4} className=''>
                    <Grid item xs={12}>
                        <h1 className='text-3xl font-medium flex items-center'>
                            <FaChevronLeft className='text-xl mr-2' />
                            Your Profile
                        </h1>
                        
                    </Grid>
                    <Grid container xs={10}>
                        <Grid item xs={12} className=''>
                            <div className='bg-white p-12 rounded-xl shadow-xl mt-12'>
                                <Grid container xs={12} spacing={2}>
                                    <Grid item>
                                        <input type="text" placeholder="Salutation" className='border border-[#5E5E5E] py-2 px-4 rounded-md' />
                                    </Grid>
                                    <Grid item>
                                        <input type="text" placeholder="First Name" className='border border-[#5E5E5E] py-2 px-4 rounded-md' />
                                    </Grid>
                                    <Grid item>
                                        <input type="text" placeholder="Last Name" className='border border-[#5E5E5E] py-2 px-4 rounded-md' />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className='pt-4'>
                                    <input type="text" placeholder="Title" className='border border-[#5E5E5E] py-2 px-4 rounded-md' />
                                </Grid>
                                <Grid item xs={12} className='pt-4'>
                                    <input type="email" placeholder="Email" className='border border-[#5E5E5E] py-2 px-4 rounded-md' />
                                </Grid>
                                <Grid item xs={12} className='pt-4'>
                                    <input type="text" placeholder="Mobile" className='border border-[#5E5E5E] py-2 px-4 rounded-md' />
                                </Grid>
                                <Grid item xs={12} className='pt-4'>
                                    <input type="text" placeholder="Telefone" className='border border-[#5E5E5E] py-2 px-4 rounded-md' />
                                </Grid>
                                <Grid item xs={12} className='pt-4' align="right">
                                    <button className='text-[#6D7879] mr-4'>Cancel</button>
                                    <button className='bg-[#FFC734] text-white rounded-md py-1 px-8'>SAVE</button>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
        </>
    )
}

export default ProfilePersonalEmployee