import React, { Fragment } from 'react'
// @mui
import Grid from "@mui/material/Grid"
// react icons


const Reports = () => {
    return (
        <>
            <div className='bg-[#F2F2F2] px-8 py-12'>
                <Grid container xs={12}>
                    <Grid container xs={12} spacing={2} className='justify-between' align="left">
                        <Grid item>
                            <h1 className='text-[#050009] font-medium text-3xl'>
                                Users
                            </h1>
                        </Grid>
                        <Grid item>
                            <button className='bg-[#FFC734] text-white text-sm py-2 px-10 rounded-md'>Create User</button>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} className="mt-8">
                        <Grid container lg={12} xs={12}>
                            <div className='bg-white w-full rounded-xl shadow-lg px-8 py-6'>
                                <table className='block mt-2 overflow-x-auto whitespace-nowrap w-full'>
                                    <thead className='table w-[100%]'>
                                        <tr className='flex justify-between border-b border-[#CCCCCC] leading-[3]'>
                                            <td className='w-[100px] text-left font-semibold'>
                                                ID
                                            </td>
                                            <td className='w-[100px] text-left font-semibold'>
                                                Name
                                            </td>
                                            <td className='w-[100px] text-left font-semibold'>
                                                Acc Type
                                            </td>
                                            <td className='w-[100px] text-left font-semibold'>
                                                Projects
                                            </td>
                                            <td className='w-[100px] text-left font-semibold'>
                                                Delete
                                            </td>
                                        </tr>

                                    </thead>
                                    <tbody className='table w-[100%]' >
                                        <tr className='text-left flex justify-between my-2 leading-[3] border-b border-[#CCCCCC]'>
                                            <td className='w-[100px] text-left text-[14px] font-light'>2912</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>Robert</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>User</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>545</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>
                                                <button className='text-red bg-[#F0F0F0] rounded-md px-3 text-xs py-1'>Delete</button>
                                            </td>


                                        </tr>
                                        <tr className='text-left flex justify-between my-2 leading-[3] border-b border-[#CCCCCC]'>
                                            <td className='w-[100px] text-left text-[14px] font-light'>2912</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>Robert</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>User</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>545</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>
                                                <button className='text-red bg-[#F0F0F0] rounded-md px-3 text-xs py-1'>Delete</button>
                                            </td>

                                        </tr>
                                        <tr className='text-left flex justify-between my-2 leading-[3] border-b border-[#CCCCCC]'>
                                            <td className='w-[100px] text-left text-[14px] font-light'>2912</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>Robert</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>Customer</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>545</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>
                                                <button className='text-red bg-[#F0F0F0] rounded-md px-3 text-xs py-1'>Delete</button>
                                            </td>

                                        </tr>
                                        <tr className='text-left flex justify-between my-2 leading-[3] border-b border-[#CCCCCC]'>
                                            <td className='w-[100px] text-left text-[14px] font-light'>2912</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>Robert</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>User</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>545</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>
                                                <button className='text-red bg-[#F0F0F0] rounded-md px-3 text-xs py-1'>Delete</button>
                                            </td>

                                        </tr>
                                        <tr className='text-left flex justify-between my-2 leading-[3] border-b border-[#CCCCCC]'>
                                            <td className='w-[100px] text-left text-[14px] font-light'>2912</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>Robert</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>User</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>545</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>
                                                <button className='text-red bg-[#F0F0F0] rounded-md px-3 text-xs py-1'>Delete</button>
                                            </td>

                                        </tr>
                                        <tr className='text-left flex justify-between my-2 leading-[3] border-b border-[#CCCCCC]'>
                                            <td className='w-[100px] text-left text-[14px] font-light'>2912</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>Robert</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>User</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>545</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>
                                                <button className='text-red bg-[#F0F0F0] rounded-md px-3 text-xs py-1'>Delete</button>
                                            </td>

                                        </tr>
                                        <tr className='text-left flex justify-between my-2 leading-[3] border-b border-[#CCCCCC]'>
                                            <td className='w-[100px] text-left text-[14px] font-light'>2912</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>Robert</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>User</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>545</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>
                                                <button className='text-red bg-[#F0F0F0] rounded-md px-3 text-xs py-1'>Delete</button>
                                            </td>
                                        </tr>
                                        <tr className='text-left flex justify-between my-2 leading-[3] border-b border-[#CCCCCC]'>
                                            <td className='w-[100px] text-left text-[14px] font-light'>2912</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>Robert</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>Customer</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>545</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>
                                                <button className='text-red bg-[#F0F0F0] rounded-md px-3 text-xs py-1'>Delete</button>
                                            </td>

                                        </tr>
                                        <tr className='text-left flex justify-between my-2 leading-[3] border-b border-[#CCCCCC]'>
                                            <td className='w-[100px] text-left text-[14px] font-light'>2912</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>Robert</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>User</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>545</td>
                                            <td className='w-[100px] text-left text-[14px] font-light'>
                                                <button className='text-red bg-[#F0F0F0] rounded-md px-3 text-xs py-1'>Delete</button>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Reports