import React from 'react'
// @mui
import Grid from "@mui/material/Grid"

const NewsCardEmployee = () => {
    return (
        <div className="border border-[#A5B0AF] p-4 rounded-xl mb-4">
            <Grid item xs={12}>
                <p className=''>01.11.2023</p>
                <h2 className='text-xl font-semibold'>News Title</h2>
                <p className='mt-2'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec 
                    elementum ipsum. Mauris nec neque quis libero gravida molestie. 
                    Morbi facilisis interdum libero 
                    <br />
                    <strong>Read More...</strong> 
                </p>
            </Grid>
        </div>
    )
}

export default NewsCardEmployee