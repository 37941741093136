import React, { Fragment } from 'react'
// @mui
import Grid from "@mui/material/Grid"
// react icons
import EmployeeCards from '../components/EmployeeCards';
import NewsCardEmployee from '../components/NewsCardEmployee';

const DashboardEmployee = () => {
    return (
        <div className='bg-[#F2F2F2] px-8 py-12'>
            <Grid container xs={12}>
                <Grid container xs={12} className='justify-between' align="left">
                    <Grid item>
                        <h1 className='font-medium text-2xl text-[#6D7879]'>
                            All Sales News:
                        </h1>
                    </Grid>
                </Grid>
                <Grid container xs={12} className="mt-8">
                    <Grid container lg={4} xs={12} className="max-lg:mt-4 md:pr-4" align="center">
                        <div className='sticky top-4 bg-white p-4 rounded-xl shadow-lg max-h-[95vh] overflow-auto'>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <Grid item xs={12} align="left">
                                <NewsCardEmployee />
                            </Grid>
                            <button className='bg-[#FFC734] text-white font-medium w-[200px] rounded-md py-1'>More News</button>
                        </div>
                    </Grid>
                    <Grid container lg={8} xs={12}>
                        <Grid item lg={12} xs={12}>
                            <div className='sticky top-4 bg-white p-4 rounded-xl shadow-lg'>
                                <p className='text-black text-lg font-light'>01.11.2023</p>
                                <h2 className='mt-2 text-2xl font-semibold'>News Title</h2>
                                <p className='text-black text-sm mt-2 font-light'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    libero Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    liberoLorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    liberoLorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    libero
                                </p>
                                <div className='flex mt-6'>
                                    <img src={require('../../assets/brown-gray-house-blue-sky-during-daytime.png')} alt="" className='mx-2' />
                                    <img src={require('../../assets/vertical-low-angle-closeup-shot-black-roof-building.png')} alt="" className="mx-2" />
                                </div>
                                <p className='text-black text-sm mt-6 font-light'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    libero Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    liberoLorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    liberoLorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    libero
                                </p>
                                <p className='text-black text-sm mt-6 font-light'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    libero Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    liberoLorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    liberoLorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    libero
                                </p>
                                <p className='text-black text-sm mt-6 font-light'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    libero Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    liberoLorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    liberoLorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec elementum 
                                    ipsum. Mauris nec neque quis libero gravida molestie. Morbi facilisis interdum 
                                    libero
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
        </div>
    )
}

export default DashboardEmployee