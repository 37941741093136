import React from 'react'
// @mui
import Grid from "@mui/material/Grid"
//react icons
import { FaEye } from "react-icons/fa";

const Login = () => {
    return (
        <>
        <Grid container xs={12} justifyContent="center" className="bg-[#F2F2F2] min-h-[100vh]">
            <Grid container xs={10} lg={5} md={6}>
                <Grid item xs={12} className="self-center">
                    <div className='bg-white rounded-2xl shadow-lg px-8 sm:px-16 py-12'>
                        <Grid item xs={12} align="center">
                            <h2 className='text-black text-7xl font-bold'>LOGO</h2>
                            <p className='text-[#525252] text-lg mt-4'>Welcome to the company sales portal</p>
                        </Grid>
                        <Grid item xs={12} className='pt-8' align="center">
                            <div className='flex justify-center'>
                                <input 
                                    type="email" 
                                    placeholder="E-mail" 
                                    className="border border-[#5E5E5E] px-2 py-2 rounded-md w-[350px]"
                                    required 
                                />
                            </div>
                            <div className='flex justify-center mt-5'>
                                <input
                                    type="password"
                                    placeholder="Password"
                                    className='border border-[#5E5E5E] px-2 py-2 rounded-md w-[350px]'
                                    required
                                />
                                <div className='h-0 w-0 relative -left-[30px] -top-[10px] self-center'>
                                    <FaEye className='text-[#5E5E5E] text-xl' />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} className='pt-4' align="center">
                            <button className='bg-[#FFC734] text-white py-2 rounded-md font-semibold w-[50%] mt-6 min-w-[200px]'>Login</button>
                            <p className='text-[#525252] text-sm font-medium mt-4'>Forgot Password?</p>
                        </Grid>
                    </div>
                </Grid>
                
            </Grid>
            
        </Grid>
        <div className='flex justify-center'>
        <div className='absolute bottom-2'>
            <div className='flex'>
                <p className="text-[#626262] mx-6">Impressum</p>
                <p className="text-[#626262] mx-6">AGB</p>
                <p className="text-[#626262] mx-6">Datenschutz</p>
            </div>
        </div>
    </div>
        </>
    )
}

export default Login