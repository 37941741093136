import React, { useState } from 'react'
// @mui
import Grid from "@mui/material/Grid"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import DateRangePicker from '../components/DateRangePicker';

const theme = createMuiTheme();

const ManageUserAdmin = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [open, setOpen] = useState(false);
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 5,
        paddingLeft: 4,
        paddingTop: 4,
        paddingBottom: 4
    };

    return (
        <>
            <div className='bg-[#F2F2F2] h-[100vh] min-h-[100vh] px-8 py-12'>
                <Grid container xs={12}>
                    <Grid container xs={12} spacing={2} className='justify-between' align="left">
                        <Grid item>
                            <h1 className='text-[#050009] font-medium text-3xl'>
                                Users
                            </h1>
                        </Grid>
                        <Grid item>
                            <button className='bg-[#FFC734] text-white text-sm py-2 px-10 rounded-md'>Create User</button>
                        </Grid>
                    </Grid>
                    
                    <Grid container xs={12} className="mt-8">
                        <Grid container lg={12} xs={12}>
                            <div className='bg-white w-full rounded-xl shadow-lg px-8 py-6'>
                                <table className='block mt-2 overflow-x-auto whitespace-nowrap w-full'>
                                    <thead className='table w-[100%]'>
                                        <tr className='flex justify-between border-b border-[#CCCCCC] leading-[3]'>
                                            <td className='w-[100px] text-left font-semibold'>
                                                ID
                                            </td>
                                            <td className='w-[100px] text-left font-semibold'>
                                                Name
                                            </td>
                                            <td className='w-[100px] text-left font-semibold'>
                                                Acc Type
                                            </td>
                                            <td className='w-[100px] text-left font-semibold'>
                                                Projects
                                            </td>
                                            <td className='w-[100px] text-left font-semibold'>
                                                <div>Delete</div>
                                            </td>
                                        </tr>
                                
                            </thead>
                            <tbody className='table w-[100%]' >
                                <tr className='text-left flex justify-between my-2 leading-[3] border-b border-[#CCCCCC] leading-[3]'>
                                    <td className='w-[100px] text-left text-[14px] font-light'>2912</td>
                                    <td className='w-[100px] text-left text-[14px] font-light'>Robert</td>
                                    <td className='w-[100px] text-left text-[14px] font-light'>User</td>
                                    <td className='w-[100px] text-left text-[14px] font-light'>545</td>
                                    <td className='w-[100px] text-left text-[14px] font-light'>
                                        <button onClick={handleOpen} className='mx-1'>Edit</button>
                                        <button className='text-red bg-[#F0F0F0] rounded-md px-3 text-xs py-1 mx-1'>Delete</button>
                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={style}>
                                                <Grid container xs={12} spacing={4}>
                                                    <Grid item xs={12}>
                                                        <h2 className='font-medium text-black text-2xl'>Edit User</h2>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid item xs={12}>
                                                            <input type="text" placeholder="User Name" className="w-full border border-[#5E5E5E] py-2 px-4 rounded-lg" />
                                                        </Grid>
                                                        <Grid item xs={12} className='pt-6'>
                                                            <MuiThemeProvider theme={theme}>
                                                                <DateRangePicker />
                                                            </MuiThemeProvider>
                                                        </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                                <Grid item xs={12} className='pt-12' align="center">
                                                    <button 
                                                        type="submit"
                                                        onClick={handleClose}
                                                        className='bg-[#FFC734] py-1 rounded-md px-12 text-white'>Submit</button>
                                                </Grid>
                                            </Box>
                                        </Modal>
                                    </td>   
                                </tr>
                            </tbody>
                        </table>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div> 
        </>
    )
}

export default ManageUserAdmin