import React, { useState } from 'react'
// @mui
import Grid from "@mui/material/Grid"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
// react icons
import EmployeeCards from '../components/EmployeeCards';

const ProjectsSuper = () => {
    
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [open, setOpen] = useState(false);
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedFiles([...selectedFiles, ...files]);
        // Additional logic for file handling
    };
      

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 5,
        paddingLeft: 4,
        paddingTop: 4,
        paddingBottom: 4
    };

    return (
        <div className='bg-[#F2F2F2] px-8 py-12'>
            <Grid container xs={12}>
                <Grid container xs={12} spacing={2} className='justify-between' align="left">
                    <Grid item>
                        <h1 className='text-[#050009] font-medium text-3xl'>
                            All Projects:
                        </h1>
                    </Grid>
                    <Grid item>
                        <button onClick={handleOpen} className='bg-[#FFC734] text-white rounded-md font-medium py-2 px-8'>
                            Create Project
                        </button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Grid container xs={12} spacing={4}>
                                    <Grid item xs={12}>
                                        <h2 className='font-medium text-black text-2xl'>Enter Project Details</h2>
                                    </Grid>
                                    <Grid item lg={7} xs={12}>
                                        <Grid item xs={12}>
                                            <input type="text" placeholder="Project Title" className="w-full border border-[#5E5E5E] py-2 px-4 rounded-lg" />
                                            <input type="text" placeholder="Project Type" className="mt-4 w-full border border-[#5E5E5E] py-2 px-4 rounded-lg" />
                                            <input type="text" placeholder="Sales Type" className="mt-4 w-full border border-[#5E5E5E] py-2 px-4 rounded-lg" />
                                            <input type="text" placeholder="Project size in kWp" className="mt-4 w-full border border-[#5E5E5E] py-2 px-4 rounded-lg" />
                                            <input type="text" placeholder="Speciﬁc annual yield in kWh/kWp:" className="mt-4 w-full border border-[#5E5E5E] py-2 px-4 rounded-lg" />
                                            <input type="text" placeholder="Purchase price in € per kWp:" className="mt-4 w-full border border-[#5E5E5E] py-2 px-4 rounded-lg" />
                                            <input type="text" placeholder="Total price of the system in €:" className="mt-4 w-full border border-[#5E5E5E] py-2 px-4 rounded-lg" />
                                            <input type="text" placeholder="Return after costs of EEG and direct marketing:" className="mt-4 w-full border border-[#5E5E5E] py-2 px-4 rounded-lg" />
                                            <input type="text" placeholder="Individual ﬁeld:" className="mt-4 w-full border border-[#5E5E5E] py-2 px-4 rounded-lg" />
                                            <div className='mt-4'>
                                                <label htmlFor="file-input-logo" className='cursor-pointer mt-4'>
                                                    <span className='border border-black py-2 px-6 rounded-md bg-[#B4B4B4] mt-4'>
                                                    Choose Logo
                                                    </span>
                                                </label>
                                                <input
                                                    type="file"
                                                    id="file-input-logo"
                                                    onChange={handleFileChange}
                                                    style={{ display: 'none' }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={5} xs={12}>
                                        <Grid item xs={12} align="center">
                                            <label htmlFor='file-input-photo' className='cursor-pointer bg-[#EFEFEF] rounded-md h-[400px] w-full flex justify-center items-center'>
                                                <p className='font-light'>Choose Photo</p>
                                            </label>
                                            <input
                                                type="file"
                                                id="file-input-photo"
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className='pt-12' align="center">
                                    <button 
                                        type="submit"
                                        onClick={handleClose}
                                        className='bg-[#FFC734] py-1 rounded-md px-12 text-white'>Submit</button>
                                </Grid>
                            </Box>
                        </Modal>
                    </Grid>
                </Grid>
                <Grid container xs={12} className="mt-8">
                    <Grid container lg={12} xs={12} spacing={2}>
                        <Grid item lg={4} md={6} xs={12}>
                            <EmployeeCards />
                        </Grid>
                        <Grid item lg={4} md={6} xs={12}>
                            <EmployeeCards />
                        </Grid>
                        <Grid item lg={4} md={6} xs={12}>
                            <EmployeeCards />
                        </Grid>
                        <Grid item lg={4} md={6} xs={12}>
                            <EmployeeCards />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default ProjectsSuper