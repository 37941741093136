import React, { useState } from 'react'
// @mui
import Grid from "@mui/material/Grid"
// react icons
import EmployeeCards from '../components/EmployeeCards';
import NewsCardEmployee from '../components/NewsCardEmployee';

const ProjectEmployee = () => {
    const [customers, setCustomers] = useState(
        [
            {
                name: '',
                email: '',
                password: '',
            }
        ]
    )

    const addCustomer = () => {
        const newCustomers = [...customers];

        newCustomers.push({
            name: '',
            email: '',
            password: '',
        });

        setCustomers(newCustomers);
    }
    return (
        <div className='bg-[#F2F2F2] px-8 py-12'>
            <Grid container xs={12}>
                <Grid container xs={12} spacing={2} className='justify-between' align="left">
                    <Grid item>
                        <h1 className='text-[#050009] font-medium text-3xl'>
                            Project Details:
                        </h1>
                    </Grid>
                </Grid>
                <Grid container xs={12} className="mt-8">
                    <Grid container lg={12} xs={12} spacing={2}>
                        <Grid item lg={8} md={7} xs={12}>
                            <div className='bg-white rounded-xl shadow-lg py-4 px-6'>
                                <h2 className='text-lg font-bold'>Building Exterior</h2>
                                <img 
                                    src={require('../../assets/Rectangle 6.png')} 
                                    alt="building" 
                                    width="100%"
                                    className='mt-4' 
                                />
                                <table className='w-full mt-4'>
                                    <tbody className='grid'>
                                        <tr className='my-[5px]'>
                                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[280px] sm:w-[340px] pr-2'>Project type:</td>
                                            <td className='max-sm:min-w-[120px] w-[220px]'>roof area / open space</td>
                                        </tr>
                                        <tr className='my-[5px]'>
                                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[280px] sm:w-[340px] pr-2'>Sales type:</td>
                                            <td className=''>Global / divisible / purchase model</td>
                                        </tr>
                                        <tr className='my-[5px]'>
                                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[280px] sm:w-[340px] pr-2'>Project size in kWp:</td>
                                            <td className=''>1000</td>
                                        </tr>
                                        <tr className='my-[5px]'>
                                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[280px] sm:w-[340px] pr-2'>Speciﬁc annual yield in kWh/kWp: </td>
                                            <td className=''>2000</td>
                                        </tr>
                                        <tr className='my-[5px]'>
                                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[280px] sm:w-[340px] pr-2'>Purchase price in € per kWp: </td>
                                            <td>100 EURO</td>
                                        </tr>
                                        <tr className='my-[5px]'>
                                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[280px] sm:w-[340px] pr-2'>Total price of the system in €:</td>
                                            <td>100.000 EURO</td>
                                        </tr>
                                        <tr className='my-[5px]'>
                                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[280px] sm:w-[340px] pr-2'>Return after costs of EEG and direct marketing:</td>
                                            <td className=''>500 EURO</td>
                                        </tr>
                                        <tr className='my-[5px]'>
                                            <td className='font-bold min-w-[100px] w-[100px] sm:min-w-[280px] sm:w-[340px] pr-2'>individual ﬁeld:</td>
                                            <td className=''>Demo Text</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Grid item xs={12} align="left" className='pt-6'>
                                    <button className='bg-[#D9D9D9] text-black font-medium rounded-2xl py-1 px-3 text-xs font-lighter mx-1'>File Download 1 (PDF, Picture or Excel)</button>
                                    <button className='bg-[#D9D9D9] text-black font-medium rounded-2xl py-1 px-3 text-xs font-lighter mx-1'>File Download 1 (PDF, Picture or Excel)</button>
                                </Grid>
                                <Grid item xs={12} align="left" className='md:pt-2 pt-4'>
                                    <button className='bg-[#D9D9D9] text-black font-medium rounded-2xl py-1 px-3 text-xs font-lighter mx-1'>File Download 1 (PDF, Picture or Excel)</button>
                                    <button className='bg-[#D9D9D9] text-black font-medium rounded-2xl py-1 px-3 text-xs font-lighter mx-1'>File Download 1 (PDF, Picture or Excel)</button>
                                </Grid>
                                <Grid item xs={12} align="left" className='md:pt-2 pt-4'>
                                    <button className='bg-[#D9D9D9] text-black font-medium rounded-2xl py-1 px-3 text-xs font-lighter mx-1'>File Download 1 (PDF, Picture or Excel)</button>
                                    <button className='bg-[#D9D9D9] text-black font-medium rounded-2xl py-1 px-3 text-xs font-lighter mx-1'>File Download 1 (PDF, Picture or Excel)</button>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={5} xs={12}>
                            <div className='bg-white rounded-xl shadow-lg py-4 px-6'>
                                <h2 className='text-[#6D7879] text-2xl'>Send Project to Customer:</h2>
                                {customers.map((customer, index) => (
                                    <Grid item xs={12} className='py-4 border-b-2' index={index}>
                                        <input type="text" placeholder="Customer Name" className='w-full border border-[#5E5E5E] border-lg py-2 px-4 rounded-lg mt-4' />
                                        <input type="email" placeholder="Customer Email" className='w-full border border-[#5E5E5E] border-lg py-2 px-4 rounded-lg mt-4' />
                                        <input type="password" placeholder="Customer Password" className='w-full border border-[#5E5E5E] border-lg py-2 px-4 rounded-lg mt-4' />
                                        
                                    </Grid>
                                
                                ))}
                                <Grid item xs={12} className="pt-6" display="flex" justifyContent="end">
                                    <button 
                                        onClick={() => addCustomer()}
                                        className="text-lg"
                                    >
                                        Add Customer +
                                    </button>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default ProjectEmployee