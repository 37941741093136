import React, { useState } from 'react'
import Grid from "@mui/material/Grid"
// react icons
import { FaCamera, FaPhoneAlt } from "react-icons/fa";
import { MdOutlineSmartphone } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import { FaChevronRight } from "react-icons/fa";



const ProfileEmployee = () => {
    
    return (
        <>
            <div className='bg-[#F2F2F2] px-8 py-12 md:min-h-[100vh]'>
                <Grid container spacing={4} className=''>
                    <Grid item xs={12}>
                        <h1 className='text-3xl font-medium'>Your Profile</h1>
                        <p className='text-lg text-[#384041] my-2'>This is how your customer sees you:</p>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        
                        <Grid item xs={12} className=''>
                            <div className='bg-white p-4 rounded-xl shadow-xl'>
                                <Grid item xs={12} align="left">
                                    <p className='text-lg'>Ihr Persönlicher Ansprechpartner:</p>
                                    <h2 className='text-black text-xl font-medium'>Mr. Max Mustermann</h2>
                                    <p className='text-black text-md font-light my-1'>Job Title</p>
                                    <div className='my-2'>
                                        <img 
                                            src={require('../../assets/young-handsome-man-travelling-with-bag 1.png')} 
                                            alt="" 
                                            className='w-full' 
                                        />
                                        <div className='flex justify-end h-0 relative -left-2 -top-12'>
                                            <div className='rounded-full bg-[#FFC734] w-9 h-9 p-2 flex justify-end cursor-pointer'>
                                                <FaCamera className="text-white text-xl" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <div className='flex items-center'>
                                            <div className='self-center'>
                                                <MdOutlineSmartphone className='text-[#FFC734] text-lg' />
                                            </div>
                                            <div className='mx-2 self-center'>
                                                <p>0151 123456789</p>
                                            </div>
                                        </div>
                                        <div className='flex items-center mt-2'>
                                            <div className='self-center'>
                                                <IoIosMail className='text-[#FFC734] text-lg' />
                                            </div>
                                            <div className='mx-2 self-center'>
                                                <p>max.mustermann@domain.de</p>
                                            </div>
                                        </div>
                                        <div className='flex items-center mt-2'>
                                            <div className='self-center'>
                                                <FaPhoneAlt className='text-[#FFC734] text-lg' />
                                            </div>
                                            <div className='mx-2 self-center'>
                                                <p>0151 123456789</p>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <div className='bg-white p-4 rounded-2xl shadow-lg'>
                            <table className='w-full'>
                                <tbody className='leading-[3]'>
                                    <tr className='border-b border-[#6D7879]'>
                                        <td className='text-[#384041]'>Name:</td>
                                        <td className='text-[#384041]'>Mr. Max. Mustermann</td>
                                        <td style={{textAlign:'-webkit-right'}}><FaChevronRight className="text-[#384041] text-xl" /></td>
                                    </tr>
                                    <tr className='border-b border-[#6D7879]'>
                                        <td className='text-[#384041]'>Email:</td>
                                        <td>Mr. Max. Mustermann</td>
                                        <td style={{textAlign:'-webkit-right'}}><FaChevronRight className="text-[#384041] text-xl" /></td>
                                    </tr>
                                    <tr className='border-b border-[#6D7879]'>
                                        <td className='text-[#384041]'>Job Title:</td>
                                        <td className='text-[#384041]'>Mr. Max. Mustermann</td>
                                        <td style={{textAlign:'-webkit-right'}}><FaChevronRight className="text-[#384041] text-xl" /></td>
                                    </tr>
                                    <tr className='border-b border-[#6D7879]'>
                                        <td>Telefone:</td>
                                        <td>Mr. Max. Mustermann</td>
                                        <td style={{textAlign:'-webkit-right'}}><FaChevronRight className="text-[#384041] text-xl" /></td>
                                    </tr>
                                    <tr className='border-b border-[#6D7879]'>
                                        <td className='text-[#384041]'>Mobile:</td>
                                        <td className='text-[#384041]'>Mr. Max. Mustermann</td>
                                        <td style={{textAlign:'-webkit-right'}}><FaChevronRight className="text-[#384041] text-xl" /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <Grid item xs={12} display="flex" justifyContent="end">
                                <button className='bg-[#FFC734] text-white px-6 py-1 rounded-md mt-4'>Edit</button>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default ProfileEmployee