import React, { useState } from 'react'
import Editor from 'react-simple-wysiwyg';

// @mui
import Grid from "@mui/material/Grid"
// react icons

const NewsSuper = () => {
    const [html, setHtml] = useState('');
  
    function onChange(e) {
        setHtml(e.target.value);
    }

    return (
        <div className='bg-[#F2F2F2] px-8 py-12 min-h-[100vh]'>
            <Grid container xs={12}>
                <Grid container xs={12} spacing={2} className='justify-between' align="left">
                    <Grid item>
                        <h1 className='text-[#050009] font-medium text-3xl'>
                            News Posting Form
                        </h1>
                    </Grid>
                </Grid>
                <Grid container xs={12} justifyContent="center" className="mt-8">
                    <Grid container lg={12} xs={12} >
                        <Grid item xs={12}>
                            <div className='bg-white rounded-xl shadow-lg py-6 px-6'>
                                <input type="text" placeholder="Title" className='border w-full border-[#4F4F4F] rounded-lg py-2 px-4' />
                                <Grid item xs={12} className='pt-4'>
                                    <Editor value={html} onChange={onChange} />
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default NewsSuper