import React, { useState } from 'react'
import Grid from "@mui/material/Grid"
// react icons
import { FaChevronLeft } from "react-icons/fa6";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaUpload } from "react-icons/fa";



const ProfileFileUploadEmployee = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    
    const handleFileChange = (event) => {
        const files = event.target.files;
    
        setSelectedFiles([...selectedFiles, ...files]);
    
    };
    return (
        <>
            <div className='bg-[#F2F2F2] px-8 py-12 min-h-[100vh]'>
                <Grid container justifyContent='center' spacing={4} className=''>
                    <Grid item xs={12}>
                        <h1 className='text-3xl font-medium flex items-center'>
                            <FaChevronLeft className='text-xl mr-2' />
                            File Upload
                        </h1>
                        <p className='text-sm'>
                            Drag ﬁles here Upload a ﬁle or select an existing one  PDF , 
                            JPEG, PNG File Upload Datei_1_Example_01012023.pdf
                        </p>
                        
                    </Grid>
                    <Grid container xs={11} spacing={2}>
                        <Grid item lg={8} md={7} xs={12} className=''>
                            <label htmlFor="file-input" className='text-[#CDCDCD] text-6xl sm:text-8xl cursor-pointer'>

                            <div className='bg-white h-[43vh] cursor-pointer flex items-center outline-dashed outline-[#CDCDCD] outline-4 outline-offset-2 rounded-xl shadow-xl mt-12'>
                                
                                <Grid container xs={12} justifyContent='space-evenly'>
                                    <Grid item>
                                        <IoDocumentTextOutline className='text-[#CDCDCD] text-6xl sm:text-8xl' />
                                    </Grid>
                                    <Grid item>
                                        <FaUpload className='text-[#CDCDCD] text-6xl sm:text-8xl' />
                                    </Grid>
                                </Grid>
                            </div>
                            </label>
                            <input
                                type="file"
                                id="file-input"
                                onChange={handleFileChange}
                                multiple
                                style={{ display: 'none' }}
                            />
                        </Grid>
                        <Grid item lg={4} md={5} xs={12} className=''>
                            <div className='bg-white px-8 py-8 rounded-xl shadow-xl mt-12'>
                                <h2 className='text-medium'>Uploaded</h2>
                                <Grid container xs={12}>
                                    <Grid item xs={12} className='pt-4'>
                                        <div className='border border-[#A5B0AF] rounded-lg px-8 py-4'>
                                            <p className='text-sm'>
                                                Example File 1000 KW <br/>
                                                Datei_2_Example_01012023.pdf
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} className='pt-4'>
                                        <div className='border border-[#A5B0AF] rounded-lg px-8 py-4'>
                                            <p className='text-sm'>
                                                Example File 1000 KW <br/>
                                                Datei_2_Example_01012023.pdf
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} className='pt-4'>
                                        <div className='border border-[#A5B0AF] rounded-lg px-8 py-4'>
                                            <p className='text-sm'>
                                                Example File 1000 KW <br/>
                                                Datei_2_Example_01012023.pdf
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
        </>
    )
}

export default ProfileFileUploadEmployee