import React, { Fragment } from 'react'
// @mui
import Grid from "@mui/material/Grid"
// react icons
import EmployeeCards from '../components/EmployeeCards';
import NewsCardEmployee from '../components/NewsCardEmployee';

const ProjectsEmployee = () => {
    return (
        <div className='bg-[#F2F2F2] px-8 py-12'>
            <Grid container xs={12}>
                <Grid container xs={12} spacing={2} className='justify-between' align="left">
                    <Grid item>
                        <h1 className='text-[#050009] font-medium text-3xl'>
                            All Projects:
                        </h1>
                    </Grid>
                </Grid>
                <Grid container xs={12} className="mt-8">
                    <Grid container lg={12} xs={12} spacing={2}>
                        <Grid item lg={4} md={6} xs={12}>
                            <EmployeeCards />
                        </Grid>
                        <Grid item lg={4} md={6} xs={12}>
                            <EmployeeCards />
                        </Grid>
                        <Grid item lg={4} md={6} xs={12}>
                            <EmployeeCards />
                        </Grid>
                        <Grid item lg={4} md={6} xs={12}>
                            <EmployeeCards />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default ProjectsEmployee