import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
// @mui
import Grid from "@mui/material/Grid"
// react icons
import { MdHome, MdOutlineCalendarMonth } from "react-icons/md";
import { FaFileInvoice } from "react-icons/fa";
import { BsPersonBadgeFill } from "react-icons/bs";
import { IoPerson } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { GoProjectRoadmap } from "react-icons/go";
import { HiOutlineNewspaper } from "react-icons/hi";

const SuperAdmin = ({ children }) => {

    const [activeItem, setActiveItem] = useState('')
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        let activePage = window.location.href.split('/')[4];
        if (activePage.includes('-')) {
            activePage = activePage.replace(/-/g, ' ');

        }
        console.log("active Page", activePage);
        setActiveItem(activePage);
        console.log("activeItem", activeItem);

    }, [activeItem])
    return (
        <div className='max-md:flex'>
            <header className='hidden bg-[#2E3536] md:block py-6 px-10'>
                <Grid container xs={12} justifyContent='space-between'>
                    <Grid item lg={4} md={5} className='flex justify-between'>
                        <div>
                            <Link to="/super-admin/dashboard" className='text-white text-semibold'>Dashboard</Link>
                        </div>
                        <div>
                            <Link to="/super-admin/manage-users" className='text-white text-semibold'>Users</Link>
                        </div>
                        <div>
                            <Link to="/super-admin/projects" className='text-white text-semibold'>Projekte</Link>
                        </div>
                        <div>
                            <Link to="/super-admin/news/add" className='text-white text-semibold'>Sale News</Link>
                        </div>
                    </Grid>
                    <Grid item>
                        <IoPerson className='text-white text-2xl' />
                    </Grid>
                </Grid>
            </header>
            <header className='flex z-[99999] md:hidden fixed bg-white shadow-md flex items-center w-[100vw] py-3 px-4 justify-between'>
                <h1 className='text-2xl'>LOGO</h1>
                <GiHamburgerMenu className='text-[25px]' onClick={() => setSidebarOpen(!isSidebarOpen)} />

            </header>
            <nav className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <Grid item xs={12} align="center" className='fixed pl-8 w-[280px]'>
                    <div className='mt-16'>
                        <Link to="/super-admin/dashboard">
                            <div className={`flex items-center w-[100%] my-8 ${activeItem === 'dashboard' ? "border-r-2 border-r-[#FFE11B]" : ""}`} onClick={() => setActiveItem('dashboard')}>
                                <MdHome className={`text-[24px] ${activeItem === 'dashboard' ? "text-[#FFE11B]" : "text-[#A3AED0]"}`} />
                                <span className={`text-[16px] ml-3 ${activeItem === 'dashboard' ? "text-[#2B3674] font-[700]" : "text-[#A3AED0] font-[500]"}`}>Dashboard</span>
                            </div>
                        </Link>
                        <Link to="/super-admin/manage-users">
                            <div className={`flex items-center w-[100%] my-8 ${activeItem === 'manage-users' ? "border-r-2 border-r-[#FFE11B]" : ""}`} onClick={() => setActiveItem('reports')}>
                                <GoProjectRoadmap className={`text-[24px] ${activeItem === 'manage-users' ? "text-[#FFE11B]" : "text-[#A3AED0]"}`} />
                                <span className={`text-[16px] ml-3 ${activeItem === 'manage-users' ? "text-[#2B3674] font-[700]" : "text-[#A3AED0] font-[500]"}`}>Users</span>
                            </div>
                        </Link>
                        <Link to="/super-admin/projects">
                            <div className={`flex items-center w-[100%] my-8 ${activeItem === 'projects' ? "border-r-2 border-r-[#FFE11B]" : ""}`} onClick={() => setActiveItem('reports')}>
                                <GoProjectRoadmap className={`text-[24px] ${activeItem === 'projects' ? "text-[#FFE11B]" : "text-[#A3AED0]"}`} />
                                <span className={`text-[16px] ml-3 ${activeItem === 'projects' ? "text-[#2B3674] font-[700]" : "text-[#A3AED0] font-[500]"}`}>Projekte</span>
                            </div>
                        </Link>
                        <Link to="/super-admin/news/add">
                            <div className={`flex items-center w-[100%] my-8 ${activeItem === 'manage profile' ? "border-r-2 border-r-[#FFE11B]" : ""}`} onClick={() => setActiveItem('manage profile')}>
                                <HiOutlineNewspaper className={`text-[24px] ${activeItem === 'manage profile' ? "text-[#FFE11B]" : "text-[#A3AED0]"}`} />
                                <span className={`text-[16px] ml-3 ${activeItem === 'manage profile' ? "text-[#2B3674] font-[700]" : "text-[#A3AED0] font-[500]"}`}>Sale News</span>
                            </div>
                        </Link>



                        <Link to="/employee/profile">
                            <div className={`flex items-center w-[100%] my-8 ${activeItem === 'profile' ? "border-r-2 border-r-[#FFE11B]" : ""}`} onClick={() => setActiveItem('profile')}>
                                <BsPersonBadgeFill className={`text-[24px] ${activeItem === 'profile' ? "text-[#FFE11B]" : "text-[#A3AED0]"}`} />
                                <span className={`text-[16px] ml-3 ${activeItem === 'profile' ? "text-[#2B3674] font-[700]" : "text-[#A3AED0] font-[500]"}`}>Profile</span>
                            </div>
                        </Link>
                    </div>
                    <Link to="/login">
                        <button className='text-center mt-6 -ml-8 bg-[#FFE11B] text-[#292828] rounded-full px-10 py-2'>
                            Logout
                        </button>
                    </Link>
                </Grid>
            </nav>

            <div className='w-[100%] mt-8 md:mt-0'>
                {children}
            </div>
        </div>
    )
}

export default SuperAdmin