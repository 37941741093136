import React, { Fragment, useEffect, useRef } from 'react'
import Grid from "@mui/material/Grid"
// react icons
import { FaArrowTrendDown } from "react-icons/fa6";

// components
import NewsCardEmployee from '../components/NewsCardEmployee';
import EmployeeCards from '../components/EmployeeCards';


const Dashboard = () => {
        
    return (
        <>
            <div className='bg-[#F2F2F2] px-8 py-12'>
                <Grid container xs={12}>
                    <Grid container xs={12} spacing={2} className='justify-between' align="left">
                        <Grid item>
                            <h1 className='text-[#050009] font-medium text-3xl'>
                                Welcome to the Sales Portal <br/>
                                Mr. Max Mustermann
                            </h1>
                            <p className='text-[#384041] text-md mt-6'>These projects are available to you:</p>
                        </Grid>
                        <Grid item className='px-4 self-end'>
                            <h2 className='font-medium text-xl text-[#6D7879]'>Current Sales News</h2>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="mt-8">
                        <Grid container lg={8} xs={12}>
                            <div className='bg-white w-full rounded-xl shadow-lg p-12'>
                                <h2 className='text-black text-md font-semibold mb-4'>Overview</h2>
                                <Grid container xs={12} spacing={2}>
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <div className='bg-[#E5ECF6] w-full px-6 py-8 rounded-3xl'>
                                            <Grid item xs={12}>
                                                <h3 className='text-md'>Projects</h3>
                                            </Grid>
                                            <Grid container xs={12} className='mt-2' justifyContent='space-between'>
                                                <Grid item>
                                                    <p className='font-semibold text-xl'>3,671</p>
                                                </Grid>
                                                <Grid item className='self-center'>
                                                    <div className='flex justify-between items-center'>
                                                        <span className='text-sm'>-0.03%</span>
                                                        <FaArrowTrendDown className='mx-1' />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <div className='bg-[#E3F5FF] w-full px-6 py-8 rounded-3xl'>
                                            <Grid item xs={12}>
                                                <h3 className='text-md'>Admin</h3>
                                            </Grid>
                                            <Grid container xs={12} className='mt-2' justifyContent='space-between'>
                                                <Grid item>
                                                    <p className='font-semibold text-xl'>3,671</p>
                                                </Grid>
                                                <Grid item className='self-center'>
                                                    <div className='flex justify-between items-center'>
                                                        <span className='text-sm'>-0.03%</span>
                                                        <FaArrowTrendDown className='mx-1' />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <div className='bg-[#E3F5FF] w-full px-6 py-8 rounded-3xl'>
                                            <Grid item xs={12}>
                                                <h3 className='text-md'>Employee</h3>
                                            </Grid>
                                            <Grid container xs={12} className='mt-2' justifyContent='space-between'>
                                                <Grid item>
                                                    <p className='font-semibold text-xl'>3,671</p>
                                                </Grid>
                                                <Grid item className='self-center'>
                                                    <div className='flex justify-between items-center'>
                                                        <span className='text-sm'>-0.03%</span>
                                                        <FaArrowTrendDown className='mx-1' />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <div className='bg-[#E5ECF6] w-full px-6 py-8 rounded-3xl'>
                                            <Grid item xs={12}>
                                                <h3 className='text-md'>Customers</h3>
                                            </Grid>
                                            <Grid container xs={12} className='mt-2' justifyContent='space-between'>
                                                <Grid item>
                                                    <p className='font-semibold text-xl'>3,671</p>
                                                </Grid>
                                                <Grid item className='self-center'>
                                                    <div className='flex justify-between items-center'>
                                                        <span className='text-sm'>-0.03%</span>
                                                        <FaArrowTrendDown className='mx-1' />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className='bg-white shadow-xl rounded-xl px-4 py-4 w-full mt-6'>
                                    <h2 className='text-black text-md font-semibold'>Recent Users</h2>
                                    <table className='mt-4 block overflow-x-auto whitespace-nowrap w-full'>
                                        <thead className='w-full'>
                                            <tr>
                                                <td className='font-semibold w-[150px]'>ID</td>
                                                <td className='font-semibold w-[150px]' >User</td>
                                                <td className='font-semibold w-[150px]'>Projects</td>
                                                <td className='font-semibold w-[150px]'>Acc Type</td>
                                            </tr>
                                        </thead>
                                        <tbody className='w-full leading-[2.5]'>
                                            <tr>
                                                <td className='font-light w-[150px]'>1</td>
                                                <td className='font-light w-[150px]'>Robert</td>
                                                <td className='font-light w-[150px]'>21312</td>
                                                <td className='font-light w-[150px]'>Customer</td>
                                            </tr>
                                            <tr>
                                                <td className='font-light w-[150px]'>1</td>
                                                <td className='font-light w-[150px]'>Robert</td>
                                                <td className='font-light w-[150px]'>21312</td>
                                                <td className='font-light w-[150px]'>Customer</td>
                                            </tr>
                                            <tr>
                                                <td className='font-light w-[150px]'>1</td>
                                                <td className='font-light w-[150px]'>Robert</td>
                                                <td className='font-light w-[150px]'>21312</td>
                                                <td className='font-light w-[150px]'>Customer</td>
                                            </tr>
                                            <tr>
                                                <td className='font-light w-[150px]'>1</td>
                                                <td className='font-light w-[150px]'>Robert</td>
                                                <td className='font-light w-[150px]'>21312</td>
                                                <td className='font-light w-[150px]'>Customer</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Grid item xs={12} align="right">
                                        <button className='bg-[#FFC734] text-white text-sm rounded-sm py-1 px-8'>View All</button>
                                    </Grid>
                                </div>
                                <h2 className='text-black text-md font-semibold mt-8 mb-4'>Listed Projects</h2>
                                <Grid container xs={12} spacing={2}>
                                    <Grid item xs={6}>
                                        <EmployeeCards />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <EmployeeCards />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid container lg={4} xs={12} className="max-lg:mt-4 lg:pl-4" align="center">
                            <div className='sticky top-4 bg-white p-4 rounded-xl shadow-lg max-h-[95vh] overflow-auto'>
                                <Grid item xs={12} align="left">
                                    <NewsCardEmployee />
                                </Grid>
                                <Grid item xs={12} align="left">
                                    <NewsCardEmployee />
                                </Grid>
                                <Grid item xs={12} align="left">
                                    <NewsCardEmployee />
                                </Grid>
                                <Grid item xs={12} align="left">
                                    <NewsCardEmployee />
                                </Grid>
                                <Grid item xs={12} align="left">
                                    <NewsCardEmployee />
                                </Grid>
                                <Grid item xs={12} align="left">
                                    <NewsCardEmployee />
                                </Grid>
                                <Grid item xs={12} align="left">
                                    <NewsCardEmployee />
                                </Grid>
                                <Grid item xs={12} align="left">
                                    <NewsCardEmployee />
                                </Grid>
                                <Grid item xs={12} align="left">
                                    <NewsCardEmployee />
                                </Grid>
                                <Grid item xs={12} align="left">
                                    <NewsCardEmployee />
                                </Grid>
                                <Grid item xs={12} align="left">
                                    <NewsCardEmployee />
                                </Grid>
                                <Grid item xs={12} align="left">
                                    <NewsCardEmployee />
                                </Grid>
                                <button className='bg-[#FFC734] text-white font-medium w-[200px] rounded-md py-1'>More News</button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div> 
        </>
    )
}

export default Dashboard